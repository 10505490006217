.search-form {
    margin-top: 8px;
    display: flex;
    align-items: flex-start;
    button{
        margin-left: 8px;
        margin-top: 12px;
    }
    .field {
        width: 300px;
    }
}