.reset-password-form {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: wrap;

    .field {
        min-width: 150px;
        width: calc(50% - 20px);
        max-width: 300px;
        margin-right: 20px;
    }

    .confirmation {
        margin-right: 0px;
    }

    .submit-button {
        width: 100%;
        margin-top: 10px;
        margin-right: auto
    }
}

@media(max-width: 670px) {
    .reset-password-form {
        flex-direction: column;
        .field {
            width: 100%;
            margin-right: 0px;
            margin: 20px 0;
            max-width: 1000px;
        }
        .submit-button {
            .MuiButtonBase-root {
                width: 100%
            }
        }
    }
}