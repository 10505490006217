$standard-width: 70px;
$user-width: 100px;
$date-width: 80px;
$driver-width: 100px;
$promo-code-width: 90px;
$inactive-border-color: rgba(0, 0, 0, 0.38);

.order-history-tab {
    .MuiSelect-root {
        width: 120px;
    }

    .MuiCardActions-root {
        padding: 0 0 8px;
        flex-direction: column;

        & > div {
            width: 100%;
            margin-bottom: 10px;
        }

        & > .date-form {
            width: 100%;
            flex-direction: row;
            margin-left: 0px !important;
            button {
                margin-top: 11px;
            }
        }
    }

    .MuiCardActions-spacing {
        & > div > :not(:first-child) {
            margin-left: 16px;
        }
    }
    .filters-container {
        margin-bottom: 40px;


        .filters-container-header {
            margin-top: 32px;
            display: flex;
            border-bottom: 1px solid $inactive-border-color;
            max-width: 750px;
            justify-content: space-between;
            svg {
                margin-right: 20px;
                color: rgba(0, 0, 0, 0.54);;
            }
        }

        .filters-container-header:hover {
            border-bottom: 2px solid #303030;
            cursor: pointer;
        }

        .MuiFormControlLabel-label {
            color: rgba(0, 0, 0, 0.87);
        }

        .filter-fieldset {
            border: 1px solid #616161;
            margin-top: 20px;
            margin-right: 20px;
            padding: 0 14px 14px;

            legend {
                margin-bottom: 16px;
            }
        }

        .nested-form-container {
            margin-left: 28px;
            display: flex;
            flex-direction: column;
        }

        .user-search-form-container {
            display: flex;
            align-items: flex-end;
        }
    }
    .order-history-table-container {
        overflow-x: auto;
    }

    .order-history-table {
        min-width: 1200px;
        text-align: left;
    }
    .order-history-headers {
        display: flex;
        align-items: flex-end;
        flex-grow: 0;
        //The right padding accounts for the scrollbar in the body - with it, the headers and table body are aligned
        padding-right: 15px;

        & > span {
            display: table-cell;
            padding: 16px 8px 0px 8px;
            margin: 0px;
            font-weight: 500;
            min-width: $standard-width;

            &:first-of-type {
                padding-left: 16px;
                text-align: left;

                min-width: $user-width;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            &:last-of-type {
                padding-right: 16px;
                margin-right: 0px;
            }

            &:nth-child(5), &:nth-child(3) {
                min-width: $date-width;
            }

            &:nth-child(7) {
                min-width: $driver-width;
            }

            &:nth-child(12) {
                min-width: $promo-code-width;
            }
        }
    }
    //.notified-allotment {
    //    color: red;
    //}
    .order-row {
        cursor: pointer;

        &:hover {
            background-color: #f5f5f5 !important;
        }

        & > span {
            display: table-cell;
            padding: 0px 8px;
            margin: 0px;
            font-weight: 400 !important;
            min-width: $standard-width;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            &:first-of-type {
                padding-left: 16px;
                text-align: left;

                min-width: $user-width;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            &:last-of-type {
                padding-right: 16px;
                margin-right: 0px;
            }

            &:nth-child(5), &:nth-child(3) {
                min-width: $date-width;
            }

            &:nth-child(7) {
                min-width: $driver-width;
            }

            &:nth-child(12) {
                min-width: $promo-code-width;
            }
        }
    }

    .order-history-row {
        cursor: pointer;
    }
}

@media (max-width: 600px) {
    .order-history-tab {
        h5 {
            font-size: 1.1em;
        }

        .MuiCardActions-root > div {
            button {
                margin-bottom: 8px;
            }

            & > div:first-of-type {
                margin-right: 16px;
            }
        }

        .MuiFormControl-root {
            width: calc(50% - 8px);

            .MuiSelect-root{
                width: 100%
            }
        }

        .MuiFormControl-root, .MuiFormControlLabel-root, .MuiButtonBase-root {
            margin-left: 0 !important;
        }

        .MuiFormControlLabel-root {
            flex-basis: 100%;
        }
    }
}