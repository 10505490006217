

@media(max-width: 670px) {
    .user-notes-card {
        .user-notes-actions-container {
            display: flex;
            flex-direction: column;
            button {
                width: 100%;
            }
            .MuiFormControlLabel-root {
                margin-top: 10px;
                margin-right: auto;
            }
        }
    }
}