.user-page {
    .user-document, .user-page-card, .user-notes-card {
        background-color: #EFEFEF !important;
        label {
            color: #3C4858
        } 
    }

    .MuiCardMedia-root {
        display: flex;
        justify-content: center;

        img {
            max-width: 90%;
            max-height: 500px; 
            justify-content: center
        }
    }

    .get-previous-documents-button {
        margin-bottom: 10px;
    }

}


@media(max-width: 670px) {
    .user-page {
        .user-document {
            .identification-document-header, .medical-recommendation-header {
                text-align: center;
            }
        }

        .get-previous-documents-button {
            width: 100%;
            button {
                width: 100%;
            }
        }
    }
}