@import '../assets/scss/mixins.scss';

.dspr-form {
    @include responsive-modal-dialog-form;

    .drop-zone {
        text-align: center;
    }

    img {
        max-width: 100%;
        display: block;
        margin: 0 auto;
    }

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
}

.dspr-advanced-settings-form {
    .away-message-input {
        min-width: 260px;
    }
}