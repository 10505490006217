.modify-order-form {  
    .driver-select-dropdown {
        min-width: 240px;
    }

    .transfer-driver-inventory-checkbox{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .new-order-details {
        margin: 24px 0;
    }

    .MuiCard-root {
        margin-bottom: 24px;
    }

    .MuiDialog-paper {
        max-width: 480px;
    }

    .bottom-padding {
        margin-bottom: 8px
    }

    .add-coupon-section {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }
    
    .MuiCardContent-root {
        padding: 16px !important; 

        h4 {
            margin-top: 0;
        }
    }

    .MuiListItem-root {
        display: flex;
        justify-content: space-between;

        padding-left: 0;
        padding-right: 0;

        border-top: 1px solid #eee;

        .MuiListItemText-root {
            flex: 1;
        }

        .incrementer {
            margin: 0 12px;
        }

        &:last-of-type {
            border-bottom: 1px solid #eee;
        }
    }

    .modify-error {
        margin-top: 8px;
        color: #f82030;
    }

    .delete-product-icon {
        transition: color 0.2s;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.47);
    }

    .new-item-form-control {
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        width: 100%;

        .MuiAutocomplete-root {
            width: 100%;
            margin-bottom: 12px;
        }

        .incrementer {
            line-height: 36px;
            margin-right: 12px;
        }

        .reset-new-item-button {
            margin-left: auto;
        }
    }

    .order-total {
        margin-top: 24px;

        p {
            margin: 0;
        }

        .MuiCardContent-root {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
    }

    .delete-product-icon:hover {
        color: rgba(0, 0, 0, 0.87);
    }
}

@media screen and (max-width: 600px) {
    .modify-order-form {
        .MuiListItem-root {
            flex-wrap: wrap;
            justify-content: flex-start;
            padding-bottom: 20px;
    
            .MuiListItemText-root {
                flex: 1 0 100%;
            }

            .incrementer {
                margin-left: 0;
            }

            & > button {
                margin-left: auto;
            }
        }
    }
}