@mixin default-font {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1.5em;
}

@mixin sticky-bottom-card-action {
    position: sticky;
    bottom: 0;
    padding: 16px;

    background-color: #ffffff;
    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.2);
}

@mixin self-contained-scrollable-main-card {
    flex-grow: 1;
    overflow-y: auto !important;

    @media screen and (min-width: 600px) {
        margin-bottom: 20px;  
    }
}

@mixin responsive-modal-dialog-form {
    width: 100%;
    padding: 10px;

    .field {
        width: 100%;
        margin-bottom: 10px;
    }
    
    @media screen and (min-width: 600px) {
        width: 400px;
        
        .half {
            width: calc(50% - 8px);
        }

        .half:nth-child(odd) {
            margin-right: 16px;
        }
    }
}

@mixin responsive-screen($breakpoint) {
    @if($breakpoint == xs) {
        @media (max-width: 600px) { @content }
    }
    @if($breakpoint == sm) {
        @media (min-width: 600px) { @content }
    }
    @if($breakpoint == md) {
        @media (min-width: 960px) { @content }
    }
    @if($breakpoint == lg) {
        @media (min-width: 1280px) { @content }
    }
    @if($breakpoint == xl) {
        @media (min-width: 1920px) { @content }
    }
}