.dspr-full-menu-driver-card-content {

  .alert-container {
    margin-bottom: 24px;
  }

  .full-menu-status-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .full-menu-status {
      font-size: 1.25rem;
    }

    .enabled-status {
      color: #007700;
    }

    .disabled-status {
      color: #eb0000;
    }
  }


  .note-container {
    margin-bottom: 24px;
  }

  .driver-select-container {
    display: flex;
    align-items: flex-end;

    .full-menu-driver-select-dropdown {
      margin-right: 32px;
      min-width: 160px;
    }
  }

  //a message-container is no longer present in the component
  //.message-container {
  //  margin-top: 24px;
  //  width: 100%;
  //  background-color: #f1eeee;
  //
  //  p:first-child {
  //    font-size: 1.2rem;
  //    margin-bottom: 16px;
  //  }
  //
  //  p {
  //    font-size: 1rem;
  //    text-align: center;
  //  }
  //}

  .loading-container {
    margin-top: 16px;
  }

}