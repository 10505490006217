.add-dsp-product-to-brand-form {
    display: flex;
    align-items: flex-end;

    .autocomplete {
        min-width: 375px;
    }

    button {
        margin-left: 16px;
    }

}