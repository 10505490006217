form {

    .underline {
        text-decoration: underline;
    }

    .product-info-container {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 32px;

        .product-name-container {
            margin-bottom: 14px;

            .product-item:first-child {
                margin-bottom: 14px;
            }
        }
    }

    .product-attributes-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .attribute-item {
            display: flex;
            flex-direction: column;
            margin-right: 32px;
        }

    }
    
    .attribute-text {
        color: rgb(87, 87, 87);
        // color: rgba(0, 0, 0, 0.54); //matches MUI secondary color
    }

    .actions-text {
        font-size: 1.25rem;
        margin-bottom: -28px;
    }

    .actions-container {
        display: flex;
        flex-direction: column;

        .fetch-metrc-tag-button-container {
            // align-self: flex-end;
            margin-top: 14px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            // justify-content: flex-start;

            p {
                margin-right: 12px;
            }
        }
    }


    .button-container {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        button:first-of-type {
            margin-right: 20px;
        }
    }
}