@import '../assets/scss/mixins.scss';

.managers-tab {
    height: 100vh;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
}

.managers {
    @include self-contained-scrollable-main-card;
    
    .MuiCardActions-root {
        @include sticky-bottom-card-action;
    }
}

.dspr-manager-toggler-form {
    .MuiSwitch-root {
        margin-left: auto;
    }

    .MuiListItem-root {
        padding-left: 0;
    }
}