.users-management {
    .date-form, .date-range {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .download-csv-button-container {
        margin-top: 20px;
    }

    .date-range {
        margin-top: 12px;

        .start-date, .end-date {
            margin-right: 12px;
            margin-bottom: 0px;
            min-width: 250px;
        }
    }

    .date-form .MuiButton-root {
        margin-top: 24px;
    }

    .MuiButton-root:hover {
        color: white;
    }
    
    .user-metrics {
        margin-top: 30px;
    }

    .users-table {
        min-width: 900px;

        header, .user-row {    
            & > span:nth-child(-n+3), & > span:nth-child(5) {
                flex: 1.5;
            }

            & > span:nth-child(n+4) {
                flex: 1;
            }

            & > span:nth-child(n+2) {
                text-align: center;
            }

            & > span:not(:first-child):not(:last-child) {
                margin: 0 4px;
            }
        }
    
        header {
            padding: 12px 0;
            border-bottom: 1px solid #ccc;
        }

        .user-row {
            cursor: pointer;
        }
        
        .user-row:hover {
            background-color: #f5f5f5 !important;
        }
    
        .user-row:not(:last-child) {
            border-bottom: 1px solid #ddd;
        }
    }
}