@import '../assets/scss/mixins.scss';

.dsp-form {
    // width: 400px;
    // padding: 10px;
    // .field {
    //     width: 300px;
    //     margin-bottom: 10px;
    // }
    @include responsive-modal-dialog-form;
}