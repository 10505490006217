.analytics-tab {
    .MuiSelect-root {
        min-width: 160px;
        margin-right: 16px;
    }

    .analytics-table-container {
        overflow-x: auto;
        padding-top: 0;
    }

    .chart-container {
        margin-bottom: 20px;
        h4 {
            margin-top: 5px;
            margin-bottom: 10px;
        }
        .legend {
            display: flex;
            flex-direction: row;
            align-items: center;
            p {
                margin-bottom: 0px;
            }
        }
        .legend-container {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            .legend-primary, .legend-secondary, .legend-tertiary, .legend-quaternary {
                display: inline-block;
                width: 14px;
                height: 14px;
                margin-bottom: 1px;
                margin-right: 5px;
            }
            .legend-primary {
                background-color: #00bcd4;
            }
            .legend-secondary {
                background-color: #f05b4f;
            }
            .legend-tertiary {
                background-color: #f4c63d;
            }
            .legend-quaternary {
                background-color: #d703fc;
            }
           
        }
        .ct-series-d {
            .ct-line {
                stroke: #d703fc !important;
            }
            
            .ct-point {
                stroke: #d703fc;
            }
        }
    }

    .analytics-row {
        cursor: pointer;
    }

}