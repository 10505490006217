.product-row {
    .product-name {
        padding-left: 2rem;
        color: rgba(0,0,0,1);
    }
    .product-name:hover {
        background-color: rgba(0, 0, 0, 0.04);;
        padding: .5rem 0 .5rem 2rem;
    }
    .product-button {
        margin: 0 1rem 0 1rem;
    }
}


.product-table-container {
    background-color: #fafafa;

    .product-table-alert-container {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;

        .product-table-alert {
            width: 100%;
            max-width: 700px;
            align-items: center;

            .MuiAlert-icon {
                margin-right: 24px;
            }
        }
    }
}