@import '../assets/scss/mixins.scss';

//Colors come from material-ui
//$inactive-border-color: rgba(0, 0, 0, 0.87);
$inactive-border-color: rgba(0, 0, 0, 0.38);
$border-focus-color: rgb(63, 81, 181);

$code-width: 100px;
$ftu-user-width: 120px;


.coupons {
    .MuiExpansionPanelSummary-root {
        padding-left: 20px;
        padding-right: 20px;
    }

    .coupon-search-expansion-panel {
        width: 100%;
    }

    .coupon-table-container {
        margin-top: 16px;
    }

    .coupon-filter-dropdown {
        display: inline-block;
        width: 72px;
        margin: 0 8px 8px 0;
    }

    .coupon-row {
        padding: 4px 0;
        background-color: transparent;
        transition: background-color 0.2s linear !important;
        cursor: pointer;

        .activation-toggle {
            cursor: default;
        }
    }

    .coupon-row:hover {
        background-color: #f5f5f5;
    }

    .coupon-row:not(:last-child) {
        border-bottom: 1px solid #ddd;
    }

    header {
        display: flex;
        align-items: flex-end;
    }

    header > span:first-child {
        min-width: $code-width;
    }

    .coupon-row > span:first-child {
        min-width: $code-width;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    header > span:nth-child(2) {
        min-width: $ftu-user-width;
    }

    .coupon-row > span:nth-child(2) {
        min-width: 120px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .filters-container {
        margin-bottom: 40px;

        .filters-container-header {
            margin-top: 32px;
            display: flex;
            border-bottom: 1px solid $inactive-border-color;
            max-width: 750px;
            justify-content: space-between;
            svg {
                margin-right: 20px;
                color: rgba(0, 0, 0, 0.54);;
            }
        }

        .filters-container-header:hover {
            border-bottom: 2px solid #303030;
            cursor: pointer;
        }

        .MuiFormControlLabel-label {
            color: rgba(0, 0, 0, 0.87);
        }

        .filter-fieldset {
            border: 1px solid #616161;
            margin-top: 20px;
            margin-right: 20px;
            padding: 0 14px 14px;

            legend {
                margin-bottom: 16px;
            }
        }

        .nested-form-container {
            margin-left: 28px;
            display: flex;
            flex-direction: column;
        }

        .user-search-form-container {
            display: flex;
            align-items: flex-end;
        }
    }

    .no-results-container {
        max-width: 750px; // same width as filters-container-header
        display: flex;
        justify-content: center;
    }

    .card-actions-button-container {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 15px;
    }

    .permissions-card-container {
        margin-top: 24px;

        .permissions-card-header-container {
            display: flex;
            cursor: pointer;

            p {
                margin-right: 50px;
            }

            svg {
                color: rgba(0, 0, 0, 0.54);
            }
        }

        .MuiCardHeader-title {
            font-size: 1.25rem;
        }

        .permissions-explainer-container {
            margin-top: 32px;
            max-width: 525px;

            dl {
                border: 2px solid #ccc;
                padding: 8px;
                width: fit-content;
                display: flex;
                flex-flow: row wrap;
                margin-top: 8px;
            }
            dt {
                font-weight: bold;
                flex-basis: 15%;
            }
            dt::after {
                content: ":";
            }
            dd {
                margin: 0;
                padding-bottom: 8px;

                flex-basis: 85%;
                flex-grow: 1;
            }

            dd:last-of-type {
                padding: 0;
            }
        }
    }

}

@media (max-width: 600px) {
    .coupon-table {
        width: 800px;
    }

    .new-coupon-form {
        .MuiDialogTitle-root {
            padding: 16px 16px 0;
        }
    
        .MuiDialogContent-root {
            padding: 8px 16px;
        }
    
        .MuiTab-root {
            padding: 8px 12px;
            min-width: 25%;
            min-height: 40px;
        }
    }
}

@media screen and (min-width: 601px) and (max-width: 700px) {
    .coupons {

        header > span {
            font-size: 12px;
            margin: 0 4px;
        }

        //adjusting code and ftu column width
        header > span:first-child, header > span:nth-child(2) {
            min-width: 75px;
        }

        //adjusting code and ftu column width
        .coupon-row > span:first-child, .coupon-row > span:nth-child(2) {
            min-width: 75px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        //increase margin to improve readability (1st and second column are text heavy)
        header > span:first-child, .coupon-row > span:first-child {
            margin-right: 8px;
        }
    }
}