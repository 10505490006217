form {
    min-width: 300px;
    max-width: 800px;
    
    .submit-button-container {
        margin-top: 16px;

        .MuiTouchRipple-root {
            width: 100%;
        }
    }
}
