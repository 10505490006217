form {

  .title {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }

  .field {
    margin-top: 16px;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    padding: 0 0 8px 0;
    margin-top: 32px;
  }
}