.inventory-transaction-form {

  .metrc-tag-autocomplete-container {
    display: flex;
    margin-top: 12px;
    flex-wrap: wrap;

    .metrc-tag-autocomplete-field-container {
      width: 60%;
      margin-bottom: 16px;
    }

    .metrc-tag-autocomplete-button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 16px;

      @media (max-width: 685px) {
        margin-left: 0;
        width: 100%;
        margin-top: 12px;
      }

      p {
        margin-right: 12px;
      }

      button {
        width: max-content;
      }
    }
  }

  .submit-button-container {
    padding-left: 0;
    
    @media (max-width: 685px) {
      margin-top: 20px;
    }

  }

}

