.metrc-tag-product-lookup-form-virtualized-table {
    padding-top: 24px; 
    
    .metrc-product-lookup-table {
        min-width: 940px;

        header > span {
            min-width: 80px;
        }

        header > span:first-of-type, header > span:nth-child(2) {
            padding: 0 16px 0 16px;
            min-width: 300px;
        }

        .quantity-header {
            display: flex;
            justify-content: center;
            align-items: center;

            .MuiSvgIcon-root {
                margin-left: 12px;
                font-size: 16px;
            }
        }

        .metrc-product-lookup-content {
            width: 100%;

            .metrc-product-lookup-row {

                span {
                    min-width: 80px;
                }

                span:first-child, span:nth-child(2) {
                    padding: 0 16px 0 16px;
                    min-width: 300px;
                }

                span:last-child {
                    margin-right: 16px;
                }

                
                .row-tag-container {
                    display: flex;
                    min-width: 300px;
                    align-items: center;
                    
                    .modify-button {
                        margin-left: 20px;
                        width: fit-content;
                        padding: 0;
                    }
                }
            }
            
        }

    }
}