.dsp-management {
    .dsp-metrics {
        margin-top: 30px;
    }

    h4 {
        margin: 5px 0;
    }

    .dsp-card, .dsp-add {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        min-height: 100px;
        height: 100%;
    }

    .MuiButtonBase-root {
        height: 100%;
    }

    .applicable-taxes {
        margin-top: 16px;
    }

    .admin-buttons {
        display: flex;
        flex-direction:column;
        padding: 8px;
        button {
            width: 100;
            margin-top: 8px;
            margin-bottom: 8px;
        }
    }

    @media (min-width: 700px) {
            .admin-buttons {
                display: flex;
                flex-direction:row;
                padding: 8px;
                button {
                    width: 50%;
                    max-width: 300px;
                    min-width: 200px;
                    margin-left: 8px;
                    margin-right: 8px;
                }
            }
        
    }
}