.ApplicableTaxWithDetailsCard.MuiCard-root {
  min-width: 400px;
  //max-width: 600px;
  overflow: visible;
  //danger:

  .card-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    //Matches card content padding
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;

    h5{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 50px;
    }

    //h5:hover {
    //  text-overflow: clip;
    //  white-space: normal;
    //  word-break: break-all;
    //}

    //use .active-switch-container to align the activation switch to the right if the screen size forces the flex items to wrap.
    //  (also remove justify-content: space between from .card-header)

    //.active-switch-container {
    //  margin-left: auto;
    //}
  }

  ul {
    padding-top: 0;

    li:first-child {
      padding-top: 0;
    }
  }

  .button-container {
    margin-top: 8px;
    padding: 0 16px;

      button {
        margin-right: 16px;
      }

      button.MuiButton-outlinedSizeSmall {
        padding: 4px 10px;
      }

    }

  }