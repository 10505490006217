.snackbar-alert-container {
  width: 50%;
  min-width: 100px;
  max-width: 600px;

  .snackbar-alert {
    width: 100%;
    align-items: center;
  }

  .snackbar-alert-message {
    margin-left: 8px;
  }
}