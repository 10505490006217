.order-detail-content {
    .spinner-container {
        display: flex;
        justify-content: center;
    }

    .coupon-flexbox {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    .indica, .sativa, .hybrid, .cbd, .vaporizer {
        font-weight: bold;
    }
    
    .indica {
        color: #cc99ff
    }

    .sativa {
        color: #67cb33 !important;
    }

    .hybrid {
        color: #fc952a !important;
    }

    .cbd {
        color: #3075e5 !important;
    }

    .vaporizer {
        color: #ddd !important;
    }

    .product-price {
        margin-left: 12px;
    }

    .strikethrough {
        text-decoration: line-through;
    }

    .error {
        margin-top: 8px;
        color: red;
    }
}
