@import '../assets/scss/mixins.scss';

.virtualized-table-container {
    overflow-x: auto;
}

.virtualized-table {
    header {
        padding-bottom: 12px;
        border-bottom: 2px solid #ccc;
    }

    header > span:last-of-type {
        margin-right: 16px;
    }

    header {
        display: flex;
        align-items: center;
    }

    header > span, .virtualized-table-content div > span {
        @include default-font;
        display: inline-block;
        font-size: 14px;
        flex: 1;
        text-align: center;

        &:not(:first-child):not(:last-child) {
            margin: 0 4px;
        }
    }
}

@media (max-width: 600px) {
    .virtualized-table {
        width: 800px;
    }
}