@import './material-dashboard-pro-react/mixins/chartist';

main {
    padding: 0 20px 20px;
}

.MuiAutocomplete-option {
    display: block !important;
    overflow-wrap: break-word;
}

@media (max-width: 600px) {
    main > h2 {
        font-size: 2em;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    body {
        min-height: 100vh; // fallback for unsupported browsers
        min-height: calc(var(--vh, 1vh) * 100); // Solves modal form clipping issue
    }
}