.user-medical-recommendation-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    .fields-container {
        flex-direction: row;
        display: flex;
        margin-top: 10px;

        .MuiFormControl-marginNormal {
            margin: 0 0;
        }

        .field {
            min-width: 180px;
            width: calc(50% - 5px);  
            &:first-child {
                margin-right: 10px;
            } 
        }
    }

    .state-issued-checkbox {
        margin-top: 10px;
    }
    
    .button-container {
        display: flex;
        flex-direction: row;
        .verify-button-container {
            margin-right: 10px
        }
    }
}

@media(max-width: 670px) {
    .user-medical-recommendation-form {
        .fields-container {
            flex-direction: column;
            margin-top: 0;

            .field{
                width: 100%;
                margin: 20px 0;
            }
        }

        .button-container {
            display: flex;
            flex-direction: column;
            .verify-button-container {
                margin-right: 0;
                margin-bottom: 15px;
            }
            button {
                width: 100%;
            }
        }
    }
}