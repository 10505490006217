.analytics-tab {
    .audit-card {
        display: flex;
        flex-direction: row;
    }
    .no-items {
        padding-left: 16px;
        padding-top: 16px;
    }
    .audit-search-bar {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        .clear-search {
            margin-left: 8px;
        }
    }
    .audit-buttons-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 8px;
        padding-right: 8px;
        .audit-button {
            font-size: 32px;
        }
    }
    
}