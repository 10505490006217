.dspr-coupon-permissions-form {
  max-width: 525px;

  .select-form-control {
    min-width: 175px;
    //width: 100%;
  }

  td.MuiTableCell-root {
    //padding: 12px 16px;
    font-size: 1rem;
  }

  .MuiSelect-select.MuiSelect-select {
    padding-right: 32px;
  }

  .button-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    button:first-of-type {
      margin-right: 25px;
    }

    button.MuiButton-outlinedSizeSmall {
      padding: 4px 10px;
    }
  }
}