.incrementer {
    border: none;
    background-color: #f2f2f2;
    box-shadow: 0px 1px 1px rgba(0, 0 ,0, 0.2);

    button {
        -webkit-appearance: none;
        border: none;
        font-size: 14px;
        padding: 4px 12px;
        line-height: 20px;
    }

    span {
        font-weight: bold;
    }
}