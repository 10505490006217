.Unauthorized-Access-Page {
  padding: 150px 0;
  position: relative;
  // min-height: 100vh;
  min-height: calc(100 * var(--vh));
  display: flex !important;
  margin: 0;
  border: 0;
  color: #fff;
  //align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  height: 100%;

  &:before {
    background-color: rgba(0, 0, 0, 0.65);
  }

  &:before, &:after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .unauthorized-card {
    max-width: 400px;
    height: 400px;
    z-index: 4;
    padding-left: 15px;
    padding-right: 15px;

    .unauthorized-card-header {
      margin-bottom: 20px;
      text-align: center;
      background: linear-gradient(60deg, #333333, #444444) !important;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(80,80,80,.4) !important;
      margin-top: -20px;
      padding: 15px;
      border-radius: 3px;

      img {
        width: 80%;
        height: auto;
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      //margin-top: 16px;

      .card-message {
        flex-grow: 1;
        font-size: .875rem;
        margin-top: 32px;
      }

      .back-button {
        margin-top: 32px;

        .button-text {
          margin-left: 8px;
        }
      }
    }

  }
}


@media screen and (max-height: 600px) {

  .Unauthorized-Access-Page {
    padding: 20px 0;
    align-items: center;

    .unauthorized-card {
      height: 100%;

      .card-title {
        font-size: 1.25rem;
      }

      .back-button {
        margin-top: 20px;
      }
    }
  }
}

@media screen and (min-height: 601px) and (max-height: 730px) {
  .Unauthorized-Access-Page {
    padding: 80px 0;
  }
}