.audit-view-page {
    .audit-card {
        display: flex;
        flex-direction: row;
    }
    .no-items {
        padding-left: 16px;
        padding-top: 16px;
    }
    .audit-action-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .audit-search-bar {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            .clear-search {
                margin-left: 8px;
            }
        }
        .audit-actions {
            display: flex;
            flex-direction: row;
            .audit-action-button {
                margin-left: 8px;
            }
        }
    }
    
    .audit-buttons-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 8px;
        padding-right: 8px;
        .audit-button {
            font-size: 32px;
        }
    }

    .audit-view-loading {
        margin-left: 12px;
        margin-top: 12px;
    }

    .product-audit-row {
        display: flex;
        span {
            display: flex;
            width: 100px;
            flex-grow: 1;
            padding-left:4px;
            padding-right:4px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        
    }
    .audit-header-row {
        display: flex;
        width: calc(100% - 25px) !important;
        span {
            padding-top: 8px;
            width: 100px;
            flex-grow: 1;
            padding-bottom: 8px;
            display: 'flex';
            align-items: 'center';
            text-align: center;
        }
    }
    .inventory-audit-table {
        min-width: 1500px;
        span {
            width: 100px;
            flex-grow: 1;
            &.number {
                max-width: 75px;
            }
            &.sales {
                max-width: 100px;
            }
            &.transactions {
                text-align: left;
                margin-left: 12px;
                min-width: 250px;
                white-space: normal;
                ul { 
                    padding: 0 0 0 20px;
                }
            }
        }
    }
}


