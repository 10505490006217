.coupon-forms {
    fieldset {
        display: block;
        margin: 32px 0;
    }

    legend {
        margin-bottom: 8px;
    }

    input[type=text] {
        width: 320px;
    }

    .MuiTextField-root {
        display: block;
        margin-top: 12px;
    }

    .submit-button-container {
        margin-top: 32px;

        button:first-child {
            margin-right: 24px;
        }
    }
}