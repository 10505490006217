.dspr-dashboard-stats-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    margin: 20px 0;

    .stat-card {
        // width: calc(25% - 12px);
        width: calc(33% - 12px);
    }

    button {
        -webkit-appearance: none;
        border: none;
        padding: 0;
        cursor: pointer;
        font: inherit;
        text-decoration: underline;
        color: #3f51b5;
    }
}

.dspr-promotion-form {
    .MuiInputBase-root {
        min-width: 240px;
    }
}

.dspr-category-form {
    .MuiPaper-root {
        max-height: 640px;
    }

    .MuiDialogActions-root {
        box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.2);
    }
}

@media screen and (max-width: 600px) {
    .dspr-dashboard-stats-container .stat-card {
        width: 100%;
    }
}



