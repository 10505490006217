@import '../assets/scss/mixins.scss';

.zipcode-tab {
    height: 100vh;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
}

.zipcodes {
    @include self-contained-scrollable-main-card;

    .MuiCardActions-root {
        @include sticky-bottom-card-action;
    }

    .min-order-size-dollar-sign {
        line-height: 32px;
        margin-right: 4px;
    }

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
}

@media (max-width: 600px) {
    .zipcode-min-order-size-input {
        width: calc(100% - 12px);
    }
}