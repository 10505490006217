.product {
    display: flex;
    flex-direction: column;
    margin: 2px;
    cursor: pointer;
    .grow {
        flex-grow: 1
    }

    img {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .title-bar {
        display: flex;
        margin-top: auto;
        flex-direction:column;
        justify-content: flex-end;
        background-color: #000000;
        opacity: 0.5;
        position: relative;
        z-index: 2;
        h4, p {
            color: #FFFFFF;
            opacity: 1;
            margin-left: 5px;
        }
    }
}