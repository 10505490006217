.dsp-product-form {
    .field {
        width: 300px;
        margin-bottom: 15px;
    }
    .half {
        width: 146px;
        margin-right: 8px;
    }

    .fieldset {
        margin-top: 15px;
    }
}