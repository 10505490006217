@import '../assets/scss/mixins.scss';

//Variables

//colors used for product availability rows
$default: white;
$not-carried-by-driver: #FFE1E2;
$no-price: #FF851B;

.dspr-inventory {
    h3 {
        margin-bottom: 0;
    }

    .card-container {
        margin-bottom: 20px;
    }

    .available-inventory-csv-button  {
        margin-left: 12px !important;
        a {
            color: white !important;
        }
    }

    .inventory-transaction-forms {
        .MuiFormControl-root {
            margin: 4px 12px 4px 0;
        }

        .metrc-product-lookup-card-header {
            padding-bottom: 0;
        }

        .metrc-product-lookup-card-body {
            padding-top: 0;
        }
    }

    .cost-or-quantity-select {
        min-width: 160px !important;
    }

    .availability-table-container-cardbody, .current-inventory-table-container-cardbody {
        .MuiFormControlLabel-label {
            color: #000 !important;
        }
    }

    .inventory-search-autocomplete {
        @include responsive-screen(xs) {
          min-width: 200px;
          width: 100%;
        }
        @include responsive-screen(sm) {
          width: 350px;
        }
        @include responsive-screen(md) {
          width: 450px;
        }
        @include responsive-screen(lg) {
          width: 700px;
        }
    }

    .snackbar-alert-container {
        width: 50%;
        min-width: 100px;
        max-width: 600px;

        .snackbar-alert {
            width: 100%;
            align-items: center;
        }

        .snackbar-alert-message {
            margin-left: 8px;
        }
    }
}

.availability-table, .inventory-detail-table {

    header {
        padding-bottom: 12px;
        border-bottom: 2px solid #ccc;
    }

    header > span:last-of-type {
        margin-right: 16px;
    }

    header, .availability-row, .inventory-detail-row {
        width: 100%;
        & > span {
            @include default-font;
            font-size: 14px;
        }

        & > span:not(:first-child) {
            flex: 1;
            text-align: center;
        }

        & > span:not(:first-child):not(:last-child) {
            margin: 0 4px;
        }
    }

    .availability-row, .inventory-detail-row {
        transition: background-color 0.2s linear !important;
    }

    .availability-row > span:first-child, .inventory-detail-row > span:first-child {
        text-align: left;
    }

    .availability-row:not(:last-child), .inventory-detail-row:not(:last-child) {
        border-bottom: 1px solid #ddd;
    }
}

.availability-table {
    width: 100%;
    overflow-y: auto;
    .virtualized-table-content {
        & > div {
            overflow-x: visible !important;
        }
        position: relative;
        width: auto;
        overflow: visible !important;
    }
    .availability-row {
        width:auto !important;
        overflow-x: visible;
        & > span {
            width: 62px;
            min-width: 60px;
        }
        &.row-bg-default {
            background-color: $default;
        }

        &.row-bg-not-carried-by-driver {
            background-color: $not-carried-by-driver;
        }

        &.row-bg-no-price {
            background-color: $no-price;
        }

    }

    header {
        width:fit-content;
        & > span {
            width: 62px;
            min-width: 60px;
        }
    }
    header > span:first-child, .availability-row > span:first-child {
        min-width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        @supports (display: -webkit-box) {
            /* autoprefixer: off */
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            white-space: normal;
        }
    }

    .availability-row:hover {
        background-color: #f5f5f5 !important;
    }
}

.MuiCardContent-root.availability-table-key {
    text-align: center;
    width: fit-content;
    padding-top: 0;

    legend {
        margin-bottom: 10px;
        font-size: 1rem;
    }

    .availability-table-key-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: fit-content;
        margin-bottom: 10px;
        flex-wrap: wrap;

        .availability-table-key-entry {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 24px;
            p {
                margin-bottom: 0px;
            }

            .not-carried-by-driver, .no-price {
                display: inline-block;
                width: 14px;
                height: 14px;
                margin-bottom: 1px;
                margin-right: 5px;
                border: 1px solid #4e4e4e;
            }

            .not-carried-by-driver {
                background-color: $not-carried-by-driver;
            }

            .no-price {
                background-color: $no-price;
            }

        }
        .availability-table-key-entry:last-child {
            margin-right: 0;
        }
    }
}

.inventory-detail-table {
    header > span:first-child, .inventory-detail-row > span:first-child {
        flex: 2;
    }
}

@media (max-width: 600px) {
    .availability-table {
        width: 800px;
    }

    .availability-table, .inventory-detail-table {
        header > span:last-of-type {
            margin-right: 0;
        }
    }
}

@media screen and (max-width: 414px) {
    .inventory-detail-table {
        width: 414px !important;
    }
}