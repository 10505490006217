.login {
    padding: 120px 0;
    position: relative;
    // min-height: 100vh;
    min-height: calc(100 * var(--vh));
    display: flex !important;
    margin: 0;
    border: 0;
    color: #fff;
    align-items: center;
    background-size: cover;
    background-position: center;
    height: 100%;

    & footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        border: none !important;
    }

    &:before {
        background-color: rgba(0, 0, 0, 0.65);
    }

    &:before, &:after {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
    }

    .login-card {
        width: 400px;
        z-index: 4;
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;

        .login-header {
            margin-bottom: 20px;
            text-align: center;
            background: linear-gradient(60deg, #333333, #444444) !important;
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(80,80,80,.4) !important;
            
            img {
                width: 80%;
            }
        }

        .login-title {
            margin-top: 10px;
            margin-bottom: 3px;
            color: #555555;
        }

        .MuiSvgIcon-root {
            color: #555555;
        }
    }

    .card-hidden {
        opacity: 0;
        transform: translate3d(0, -60px, 0);
    }

    input {
        font-size: 16px;
    }
}