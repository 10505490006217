.stat-card {
    width: calc(33% - 12px);
    margin: 0;
    overflow: inherit !important;
    display: flex;
    flex-direction: column;

    p {
        color: #999;
        margin: 12px 0 4px;
    }

    h3 {
        color: #3c4858;
    }
}

.stat-card-grid {
    margin: 0;
    overflow: inherit !important;
    display: flex;
    flex-direction: column;

    p {
        color: #999;
        margin: 12px 0 4px;
    }

    h3 {
        color: #3c4858;
    }
}

@media (max-width: 675px) {
    .stat-card {
        width: 100%;

        &:not(:last-of-type) {
            margin-bottom: 36px;
        }
    }
}