
.driver-route-page {
    width: 100%;
    button {
        margin-left: 0;
        margin-bottom: 8px;
    }
    button:first-of-type {
        margin-right: 12px;
    }
    .buttons-container {
        margin-top: 8px;
    }
}

.queued-driver-route-page-orders-list  .MuiListItem-root {
    padding: 4px 2px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;

    & > button {
        margin-left: 12px;
    }
}

.new-route-dialog {
    .MuiPaper-root {
        width: 80%;
        max-width: 600px;
    }
    .error {
        color:#f44336;
    }
    .queued-driver-route-page-orders-selection-list .MuiListItem-root{
        padding: 4px 2px;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
    
        & svg {
            font-size: 24px;
            position: absolute;
            margin-left: 6px;
        }
        
        &.order-in-route {
            background-color: #ddd;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
        }
    }
}







@media(max-width: 600px) {
    .driver-route-page {
        button {
            width: 100%;
            margin-right: 0;
        }

        .queued-driver-route-page-orders-list .MuiListItem-root {
            max-width: 100%;
            flex-wrap: wrap;
    
            .MuiListItemText-root {
                width: 100%;
            }
    
            button {
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 8px;
            }
        }
    }
    
}