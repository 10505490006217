.driver-page {
    .MuiExpansionPanelDetails-root {
        display: block;
    }

    .MuiCardActions-root {
        align-items: flex-end;
    }

    .MuiAutocomplete-inputRoot, .MuiInputBase-input {
        width: 200px;
    }

    .queued-orders-list  .MuiListItem-root {
        max-width: 800px;
        padding: 4px 2px;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;

        & > button {
            margin-left: 12px;
        }
    }

    
    .product-search-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        margin-top: 16px; 
        min-height: 50px;
        .product-search-input {
            margin-right: 12px;
        }

    }

    .queued-order-total {
        color: rgba(0, 0, 0, 0.54);
        font-size: 14px;

        & svg {
            font-size: 24px;
            position: absolute;
            margin-left: 6px;
        }
    }

    .driver-on-call-toggler{
        display: flex;
        flex-direction: row;
        .toggler-text {
            display: flex;
            align-items: center;
            margin-right: 20px;
        }
    }
    

    .driver-inventory-table-container {
        overflow-x: auto;

        .driver-inventory-productName-cell {
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .MuiTabs-root {
        margin-top: 0;
    }

    & > .MuiCardContent-root {
        padding-top: 0;
        padding-bottom: 0 !important;
    }

    .add-driver-inventory-to-inventory-period-form, .driver-inventory-form, .transfer-inventory-form {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
    
        & > div {
            margin-right: 12px;
        }

        & > button {
            margin-top: 12px
        }
    }

    .transfer-inventory-form, .add-driver-inventory-to-inventory-period-form {
        margin-top: 12px;
    }
}

.driver-information-form {
    max-width: 600px;
    .MuiTextField-root {
        width: calc(50% - 10px);
        min-width: 200px;
        margin-bottom: 10px;
        margin-top: 0px !important;
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}

.driver-on-call-confirmation-button {
    display: block !important;
    margin: 0 auto !important;
}

.driver-page-order-detail-popup-card {
    overflow-y: auto !important;
}

@media (max-width: 600px) {
    .driver-page {
        form button {
            margin-top: 12px;
        }

        .queued-orders-list .MuiListItem-root {
            max-width: 100%;
            flex-wrap: wrap;
    
            .MuiListItemText-root {
                width: 100%;
            }
    
            button {
                margin-left: 0;
                margin-bottom: 8px;
            }

            button:first-of-type {
                margin-right: 12px;
            }
        }
    }

    .driver-inventory-table-container table {
        width: 600px
    }

    .driver-information-form {
        max-width: 400px;
        .MuiTextField-root {
            width: calc(100%);
            min-width: 200px;
            margin: 0 auto;
            margin-bottom: 10px;
            &:not(:last-child) {
                margin-right: 0px;
            }
        }
    }
}