.sidebar-container {
    .sidebar-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-bottom: 16px;

        .routes {
            margin-top: 8px;
            .manager-profiles-inner-route {
                background-color: rgba(200, 200, 200, 0.2);
                color: rgb(255, 255, 255);
                position: relative;
            }
            .manager-profiles-active-collapse {
                background-color: transparent;
            }
        }

        .collapsable-container {
            margin-bottom: 10px;
        }

        .quick-nav-container {
            width: calc(100% - 30px);
            margin-top: 14px;
            margin-left: 15px;
            margin-bottom: 18px;
            top: 0;
            height: 1px;
            content: "";
            position: relative;
            background-color: hsla(0,0%,100%,.3);
        }
    }

    & > .MuiDrawer-paper {
        height: 100vh;
        height: -webkit-fill-available;
        display: flex;
        flex-direction: column;
    }

    .sidebar-link, .logout-button {
        padding: 4px 12px;
        height: 40px;
    }

    .sidebar-link div, .sidebar-link span {
        line-height: 30px;
    }

    .header {
        margin: 0 15px;
        padding: 4px 12px;
        height: 40px;
        color: #b1afaf;
        width: calc(100% - 30px);
    }

    .header:first-child {
        margin-top: 10px;
    }

    .logout-button {
        display: inline-flex !important;
        margin-top: auto;
        align-items: center;
        width: calc(100% - 30px) !important;
    
        .MuiIcon-root {
            width: 30px;
            opacity: 0.8;
            margin-right: 15px;
            text-align: center;
        }
    
        span:not(.MuiIcon-root) {
            font-size: 14px;
        }
    }
    
    .return-to-dsp {
        display: block !important;
        width: calc(100% - 30px) !important;
        margin: 12px 15px 0;
        padding: 4px 0 12px !important;
    
        p {
            font-size: 18px;
            font-weight: bold;
            margin: 0;
            padding: 0 16px 4px;
        }
    
        a {
            display: flex;
            align-items: center;
            color: white;
            padding: 4px 16px;
            background-color: transparent;
            border-radius: 3px;
    
            transition: background-color 0.3s linear;
    
            .MuiIcon-root {
                transform: scale(0.8);
                margin-left: -6px;
                margin-right: 4px;
            }
        }
    
        a:hover {
            background-color: rgba(200, 200, 200, 0.2);
        }

        &:after {
            // width: calc(100% - 30px);
            width: 100%;
            bottom: 0;
            height: 1px;
            content: "";
            position: absolute;
            background-color: hsla(0,0%,100%,.3);
        }
    }
}