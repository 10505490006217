.product-search-form {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
    button {
        margin-left: 8px;
        margin-top: 12px;
    }
    .field {
        width: 300px;
    }
}