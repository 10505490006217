.dsprs-container {
    h4 {
        margin: 5px 0;
    }

    .dspr-card, .dspr-add {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100px;
        height: 100%;
    }

    .dspr-card {
        flex-direction: row;
        justify-content: space-between;

        .dspr-name, .dspr-settings {
            text-align: center;
            display: flex;
            height: 100%;
        }

        .dspr-name {
            width: 70%;
            padding: 0 10px;

            .dspr-not-managed-by-user {
                p {
                    font-size: .8rem;
                    color: #9c9c9c;
                }
            }
        }

        .dspr-settings {
            width: 30%;
            background-color: #E8E8E8;
            border-left: 1px solid #AAAAAA;
            height: 100%;
        }
    }
}