.drivers-container {
    .MuiCardActions-root {
        display: block;

        button {
            margin-right: 12px;;
        }
    }

    .driver-select-dropdown {
        margin-top: 12px;

        .MuiSelect-root {
            display: block;
            min-width: 160px;
        }   
    }
    
}

.drivers-oncall-dialog .MuiSwitch-root {
    margin-left: auto;
}

@media (max-width: 600px) {
    .drivers-container .add-driver-button {
        margin-bottom: 12px;
    }
}