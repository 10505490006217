.dspr-driver-service-area-management {
    .new-profile-dialog {
        width: 80%;
        max-width: 600px;
    }
    .menu-mechanism-select-dropdown-mobile {
        display: none;
    }

    .controls-container {
        display: flex;
        flex-direction: column;
        &>div {
            margin-bottom: 20px;
        }
        .MuiSelect-root {
            display: block;
            min-width: 160px;
        }   
        .mobile-menu-mechanism {
            width: 100%;
        }
        .active-profile {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            h4 {
                margin-right: 12px;
                margin-bottom: 0;
            }
        }
        .profile-controls {
            display: flex;
            align-items: flex-end;
            width: 100%;
            justify-content: space-between;
            .service-area-profile-data-controls {
                display: flex;
                align-items: flex-end;
                &>* {
                    margin-right: 12px;
                    &:last-child {
                        margin-right: 0px;
                    }
                }
                .service-area-profile-to-be-edited-selection {
                    min-width: 200px;
                }
                .MuiFormControlLabel-root {
                    min-width: 120px;
                }
            }
            .profile-buttons-container {
                button {
                    min-width: 150px;
                }
            }
        }
        .service-area-controls {
            display: flex;
            align-items: flex-end;
            width: 100%;
            justify-content: space-between;
            &>* {
                margin-right: 12px;
                &:last-child {
                    margin-right: 0px;
                }
            }
            .service-area-data-controls {
                display: flex;
                align-items: flex-end;
                &>* {
                    margin-right: 12px;
                    &:last-child {
                        margin-right: 0px;
                    }
                }
                .polygon-buttons-container {
                    button {
                        min-width: 150px;
                    }
                }
            }
            
        }
    }

    .menu-mechanism-select-dropdown {
        margin-top: 12px;
        
        .MuiSelect-root {
            display: block;
            min-width: 160px;
        }
    }
}

@media(max-width: 750px) {
    .dspr-driver-service-area-management {

        .menu-mechanism-select-dropdown-mobile {
            display: block;
            
        }
        .menu-mechanism-select-dropdown {
            display: none;
        }

        .controls-container {
            display: flex;
            flex-direction: column;
            &>div {
                margin-bottom: 20px;
                display: flex;
                flex-direction: column;
            }
            .MuiFormControl-root {
                width: 100%;
                .MuiInputBase-root {
                    width: 100%
                }
            }
            .MuiSelect-root {
                display: block;
                width: 100%;
            }   
            .mobile-menu-mechanism {
                
            }
            .active-profile {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                h4 {
                    margin-right: 12px;
                    margin-bottom: 0;
                }
            }
            .profile-controls {
                display: flex;
                width: 100%;
                .service-area-profile-data-controls {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;
                    &>* {
                        margin-right: 0px;
                    }
                    .service-area-profile-to-be-edited-selection {
                        min-width: 200px;
                    }
                    .MuiFormControlLabel-root {
                        min-width: 120px;
                        margin: 10px 0px;
                    }
                }   
                .profile-buttons-container {
                    flex-direction: column;
                    width: 100%;
                    button {
                        width: 100%;
                        &:first-child {
                            margin-bottom: 10px
                        }
                    }
                }
            }
            .service-area-controls {
                display: flex;
                width: 100%;
                .service-area-data-controls {
                    display: flex;
                    flex-direction: column;
                    align-self: flex-start;
                    width: 100%;
                    &>* {
                        margin-right: 0px;
                    }
                    .MuiFormControlLabel-root {
                        width: 100%;
                        min-width: 120px;
                        margin: 10px 0px;
                    }
                }
                .polygon-buttons-container {
                    flex-direction: column;
                    width: 100%;
                    button {
                        width: 100%;
                        &:first-child {
                            margin-bottom: 10px
                        }
                    }
                }
                
            }
        }

    }
}
